<template>
    <div class="bg">
        <div class="space"></div>
        <div class="header">
            <van-row class="header-title">
                <van-col span="7"><p class="topLabel">客户姓名</p></van-col>
                <van-col span="12"><p class="topLabel header-title-center-label" >{{item.name|ellipsis}}</p></van-col>
                <van-col span="5"
                         @click="edit()">
                    <p class="changeLabel">修改</p>
                    <van-icon name="edit" color="#000000" class="changeLabel" size="0.5rem" ></van-icon>
                </van-col>
            </van-row>
            <div class="line"></div>
            <van-field label="联系电话" :value="item.phoneNo" readonly  class="value-label"/>
<!--            <van-field label="银行卡号" :value="item.bankCardNo" readonly  class="value-label"/>-->
<!--            <van-field label="身份证号" :value="item.idCard" readonly  class="value-label"/>-->
            <van-field label="客户分组" :value="dictValue" readonly  class="value-label"/>
        </div>
    </div>
</template>

<script>
    import {getCustomerInfo} from "../../utils/api";
    import {Toast} from "vant";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "customerDetail",
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 7) {
                    return value.slice(0,7) + '...'
                }
                return value
            }
        },
        methods:{
            toGetCustomerInfo(){
                getCustomerInfo({
                    'customerNo':this.customerNo
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.item = res.data.customerInfo;
                            this.toGetDictValue(this.item.customerGroup)
                        }
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            edit(){
                this.$router.push({name:'editCustomer',query:{'customerNo':this.customerNo}})
            },
            toGetDictValue(dictKey){
                for (var item of this.customerGroup){
                    if (dictKey == item.dictKey){
                        this.dictValue = item.dictValue;
                    }
                }
            },
        },
        data(){
            return{
                item:{},
                dictValue:'',
                customerGroup:[],
                customerNo:''
            }
        },
        mounted() {
            console.log('mounted实例被激活时使用，用于重复激活一个实例的时候')
            // 'item':item,'customerGroup':this.customerGroup
            this.customerNo = this.$route.query.customerNo;
            this.customerGroup = JSON.parse(common.getItem('customerGroup'));
            this.toGetCustomerInfo();
            uwStatisticAction('/customerDetail','客户详情')
        },
        activated () {
            console.log('实例被激活时使用，用于重复激活一个实例的时候')
            this.toGetCustomerInfo();
        }

    }
</script>

<style lang="less" scoped>
    .bg {
        width: 100%;
        height: 100%;
        background: #eeeeee;

        .space {
            height: 0.2rem;
        }

        .header {
            margin: 0.2rem;
            background: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .header-title {
                height: 1rem;
                margin: 0 0.2rem;
                .header-title-center-label{
                    text-align: left;
                }
            }
        }
        .line{
            margin:0 0.2rem;height: 1px;background: #eeeeee
        }
        .value-label{
            color: #999999;font-weight: bold
        }
        .topLabel {
            text-align: left;
            height: 0.5rem;
            line-height: 0.5rem;
            margin-top: 0.25rem;
            border-radius: 0.05rem;
            color: black;
            font-weight: bold;
            font-size: 0.4rem;
        }

        .changeLabel {
            text-align: right;
            height: 0.5rem;
            line-height: 0.5rem;
            margin-top: 0.25rem;
            border-radius: 0.05rem;
            color: blue;
            float: right;
        }
    }

</style>
